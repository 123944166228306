import { request } from "../utils/axios"

export default {
	//{ AppCode:'程序类型编码', LoginName:'登录名', LoginPwd:'密码', DBNO:'数据库编码', DeviceCode:'设备编码' }
	login(params = {}) {
		return request("post", "/access/manager_login", params)
	},
	
	applyCrossWebTicket(params = {}){
		return request("get", "/access/apply_cross_web_ticket", params)
	}
	
}